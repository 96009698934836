<template>
  <div class="image-wrapper">
    <img
      :src="`/pics/support/${src}`"
      :alt="alt"
      :style="tempStyles"
      class="article-image"
      @click="$emit('viewer')"
    />
    <span> {{ alt }} </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "ArticleImage",
  emits: [
    'viewer',
  ],
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  setup(props) {

    const tempStyles: string =
      `width: ${ props.width ? props.width : 'auto' };` +
      `height: ${ props.height ? props.height : 'auto' };`;

    return {
      tempStyles,
    }
  }
})
</script>

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  flex-flow: column;
  gap: 4px;
  padding: 24px 0;

  .article-image {
    cursor: zoom-in;
    width: auto;
    height: auto;
    max-width: 800px;
    max-height: 400px;
    align-self: center;
  }

  span {
    color: #999999;
    font-size: 12px;
  }
}
</style>
