<template>
  <teleport to="body" v-if="show">
    <div class="image-viewer">
      <div class="image-viewer__mask" />
      <span class="viewer-button close default" @click="closeViewer">
        <ui-icon :icon="UiIconNames.Icon_Close" class="viewer-icon" :size="20"/>
      </span>
      <span class="viewer-button next default" v-if="images.length > 1 && active !== images.length - 1" @click="onNext">
        <ui-icon :icon="UiIconNames.Chevron_ArrowRight" class="viewer-icon" :size="20" />
      </span>
      <span class="viewer-button prev default" v-if="images.length > 1 && active !== 0" @click="onPrev">
        <ui-icon :icon="UiIconNames.Chevron_ArrowLeft" class="viewer-icon" :size="20" />
      </span>
      <span class="viewer-button notes">
        {{ images[active].alt }}
      </span>
      <div class="img-wrapper">
        <img :src="`/pics/support/${images[active].src}`" :alt="images[active].alt"/>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import { useEventListener } from "@vueuse/core";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ImageViewer",
  components: {
    UiIcon,
  },
  setup() {

    const {
      show,
      images,
      active,
      closeViewer,
    } = useImageViewer();

    useEventListener(window, 'keydown', (event: any) => {
      if (!show.value) return;
      switch (event.key) {
        case 'ArrowLeft': return onPrev();
        case 'ArrowRight': return onNext();
        case 'Escape': return closeViewer();
      }
    });

    function onNext() {
      if (active.value !== images.value.length - 1) active.value = active.value + 1
    }

    function onPrev() {
      if (active.value !== 0) active.value = active.value - 1
    }

    return {
      show,
      images,
      active,
      onNext,
      onPrev,
      closeViewer,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.image-viewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  .image-viewer__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .5;
    background: #000;
  }

  .viewer-button {
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: .8;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #606266;

    &.default {
      width: 40px;
      height: 40px;
    }

    &.close {
      top: 40px;
      right: 40px;
    }

    &.next {
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
    }

    &.prev {
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
    }

    &.notes {
      left: 50%;
      bottom: 30px;
      transform: translate(-50%);

      border-radius: 20px;
      width: 440px;
      height: auto;
      padding: 12px 24px;
      font-size: 14px;
      cursor: default;

      color: var(--main-color-white);
    }
  }

  .viewer-icon {
    color: var(--main-color-white);
  }

  .img-wrapper {
    position: static;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 80%;
      max-height: 80%;
      z-index: 1;
    }
  }
}
</style>
