import { ref } from "vue";

const show = ref(false);
const images = ref([]);
const active = ref(0);

export function useImageViewer() {

  function openViewer(img: any, index: number) {
    images.value = img;
    active.value = index;
    show.value = true;
  }

  function closeViewer() {
    show.value = false;
    images.value = [];
  }

  return {
    show,
    images,
    active,
    openViewer,
    closeViewer,
  }
}

