<template lang="pug">
#training-article
  .breadcrumb.main
    span.inner-link(@click="router.push({ name: Tabs.Training.Main })") Обучение
    span.separator /
    span.page {{ articleInfo.name }}
  .title {{ articleInfo.name }}
  .card-read-time._margin(v-if="articleInfo.time")
    ui-icon(:icon="UiIconNames.Icon_Clock" :size="14")
    span Время чтения: {{ articleInfo.time }}
  .content(:class="menu.length <= 1 && 'full'")
    .article-content
      .article-content.white
        slot
      router-link.breadcrumb.back(:to="{ name: Tabs.Training.Main }")
        ui-icon(:icon="UiIconNames.Chevron_Left" :size="18")
        | Вернуться к обучению
      .articles-wrapper(v-if="articleInfo?.related?.length")
        .articles-title Статьи по смежным темам
        .articles._small
          training-card(
            v-for="s of TrainingArticles.filter(e => articleInfo?.related.includes(e.key))"
            :key="s.name"
            :title="s.name"
            :time="s.time"
            :sections="s.sections"
            @click="redirect(s.key)"
          )
    .sticky-menu(v-if="menu.length > 1")
      .menu
        .menu-item(
          v-for="section of menu"
          :key="section.id"
          :class="[activeSection === section.id && '_active']"
          @click="scrollTo(section.id)"
        ) {{ section.title }}

image-viewer
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { useScroll } from '@vueuse/core';

import { Tabs, TrainingArticles } from "@/router/tabs";
import ImageViewer from "@/components/pages/training/ImageViewer.vue";
import TrainingCard from "@/components/pages/training/TrainingCard.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import type { PropType } from "vue";
import type { TagI } from "@/stores/manuals/ManualsInterface";

function useElements(selector: string) {
  const elements = ref<HTMLElement[]>([]);
  function updateElements() {
    elements.value = Array.from(document.querySelectorAll(selector)) || [];
  }
  return { elements, updateElements };
}

export default defineComponent({
  name: "ArticlePage",
  components: {
    UiIcon,
    TrainingCard,
    ImageViewer,
  },
  props: {
    menu: {
      type: Array as PropType<TagI[]>,
      default: () => [],
    },
  },
  setup(props) {

    const route = useRoute();
    const router = useRouter();

    const articleInfo = computed(() => TrainingArticles.find(e => e.key === route.name))

    const { elements: sectionsElements, updateElements } = useElements('.training-content');

    const activeSection = ref(props.menu[0]?.id);
    const skipWatch = ref(false);

    const rootContainer = ref<HTMLElement | null>(null);

    function updateContainer() {
      rootContainer.value = document.querySelector('#app-main');
    }

    const { arrivedState, y, isScrolling } = useScroll(rootContainer, { behavior: 'smooth' });

    onMounted(() => {
      updateContainer();
      updateElements();
    });

    watch(isScrolling, (v) => {
      if (!v) {
        skipWatch.value = false;
      }
    });

    watch(y, () => {
      if (skipWatch.value) return;
      let index = props.menu.length - 1;
      if (!arrivedState.bottom) {
        const nextIndex = sectionsElements.value.findIndex((s) => y.value < s.offsetTop - 0);
        if (nextIndex !== -1) {
          index = Math.max(nextIndex - 1, 0);
        }
      }

      activeSection.value = props.menu[Math.max(index, 0)].id;
    });

    function scrollTo(id: string) {
      skipWatch.value = true;
      scrollIntoView(id, 'start');
      activeSection.value = id;
    }

    function redirect(to: string) {
      router.push({ name: to })
    }

    return {
      router,
      activeSection,
      articleInfo,
      scrollTo,
      redirect,
      Tabs,
      TrainingArticles,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import '@/assets/styles/pages/training';
@import '@/assets/styles/mixin/fonts';

#training-article {
  @include average-page;

  display: flex;
  flex-flow: column;
  gap: 16px;

  .breadcrumb {
    display: flex;
    flex-flow: row;
    gap: 8px;

    font-size: 14px;
    line-height: 18px;

    &.main {
      margin-top: 24px;
    }

    &.back {
      font-weight: 700;
      width: fit-content;

      &:hover {
        color: #409eff;
      }
    }

    .inner-link {
      cursor: pointer;
      color: #409eff;
      font-weight: 700;
    }
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-top: 24px;
    text-transform: uppercase;
  }

  .content {
    display: grid;
    //grid-template-columns: auto 260px;
    //grid-column-gap: 16px;

    &:not(.full) {
      display: grid;
      grid-template-columns: 1fr 260px;
      grid-column-gap: 16px;
    }

    .article-content {
      display: flex;
      flex-flow: column;
      gap: 32px;

      &.white {
        background-color: white;
        border-radius: 6px;
        padding: 24px 32px;
        box-sizing: border-box;
      }
    }

    .menu {
      position: sticky;
      top: 44px;
      display: flex;
      flex-flow: column;
      gap: 4px;
      background-color: white;
      border-radius: 6px;
      padding: 16px 12px;
      box-sizing: border-box;
    }

    .menu-item {
      @include label-13-16;
      padding: 12px 20px;
      cursor: pointer;
      border-radius: 4px;

      &._active {
        background-color: #ecf5ff;
        color: var(--main-color-blue);
      }

      &:hover {
        background-color: #F2F3F5;
        color: var(--main-text-color);
      }
    }
  }
}
</style>
